.DateInput_input {
  background: #e1e1e1;
  width: 100%;
  padding: 4px 2px 2px;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 1px solid;
  font-size: 14px;
}
