/* This stylesheet generated by Transfonter (https://transfonter.org) on April 13, 2018 7:24 AM */

@font-face {
  font-family: "OpenSans";
  src: url("assets/font/OpenSans-LightItalic.ttf") format("truetype"),
    url("assets/font/OpenSans-LightItalic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "OpenSans";
  src: url("assets/font/OpenSans-MediumItalic.ttf") format("truetype"),
    url("assets/font/OpenSans-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "OpenSans";
  src: url("assets/font/OpenSans-BoldItalic.ttf") format("truetype"),
    url("assets/font/OpenSans-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "OpenSans";
  src: url("assets/font/OpenSans-Regular.ttf") format("truetype"),
    url("assets/font/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans-Bold";
  src: url("assets/font/OpenSans-Bold.ttf") format("truetype"),
    url("assets/font/OpenSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "OpenSans";
  src: url("assets/font/OpenSans-Italic.ttf") format("truetype"),
    url("assets/font/OpenSans-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "OpenSans";
  src: url("assets/font/OpenSans-ExtraBold.ttf") format("truetype"),
    url("assets/font/OpenSans-ExtraBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url("assets/font/MaterialIcons.eot");
  /* For IE6-8 */
  src: url("assets/font//MaterialIcons.woff2") format("woff2"),
    url("assets/font//MaterialIcons.woff") format("woff"),
    url("assets/font//MaterialIcons.ttf") format("truetype");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}
